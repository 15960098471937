<template>
  <div class="lawyerauth" style="height: 100%; padding-bottom: 100px; box-size: border-box">
    <div class="head">
      <div class="left" v-if="showBack">
        <van-icon name="arrow-left" class="arrow" @click.stop="back" />
        <van-cell title="律师认证" />
      </div>
      <div class="left" v-else>
        <van-cell title="    " />
      </div>
      <div class="right">
        <span class="intro">拍摄律师证自动填写</span>
        <i class="iconfont icon-shot" @click="addImg"></i>
      </div>
    </div>
    <div class="tab-box" v-if="!isUpdate">
      <div class="tab" v-for="(item, i) in tabList" :key="i" :style="tab === item.value ? 'border:1px solid rgba(0, 121, 254, 1)' : ''" @click="onTab(item)">
        <span :style="tab === item.value ? 'color:#3CC56D' : ''"> {{ tab === item.value ? item.selectText : item.text }}</span>
        <i v-if="tab === item.value" class="iconfont icon-chenggong" style="margin-left: 10px; color: #3cc56d"></i>
      </div>
    </div>
    <van-field class="van" v-model="lawyerName" required clearable label="姓名" placeholder="请输入律师姓名" />
    <van-field
      class="vans"
      v-model="lawFirmName"
      required
      clearable
      :label="tab === 1 ? '律所名称' : tab === 2 ? '服务所名称' : '工作单位'"
      placeholder="请输入内容"
      @focus="focus"
      @blur="blur"
      @input="inputName"
    />
    <ul class="numlist" v-show="orgShow" @scroll="load">
      <li v-for="(item, i) in lawFirmList" :key="i" @click="nameClick(item)">
        {{ item.name }}
      </li>
      <li v-if="lawFirmList && lawFirmList.length === 0" style="color: orange">暂无匹配数据</li>
    </ul>
    <van-field
      class="van"
      v-model="jobNumber"
      @blur="blurJobNum"
      :required="tab !== 3"
      clearable
      :label="tab !== 3 ? '执业证号' : '工作证号'"
      :placeholder="tab !== 3 ? '请输入执业证号' : '请输入工作证号'"
    />
    <div class="calling-card">
      <span :class="tab === 2 ? 'van-cell--required' : ''">上传名片</span>
      <div @click="addImg"><img :src="callingCardUrl" alt="" v-if="callingCardUrl" /><span v-else>拍照上传</span></div>
    </div>
    <!-- <div
      v-else
      style="text-align: right;color:#0079FE;margin: 15px"
      @click="more = true"
    >
      更多
    </div> -->
    <van-button type="info" :class="{ goback: true, gray: isClick }" @click="sure" :disabled="isClick || !validate">{{ isUpdate ? '保存' : '认证' }}</van-button>
  </div>
</template>
<script>
import goBackMixin from '@mixins/goBack'
import { qiniuBase } from '~api-config'
// import AreaList from '~api/area'
import { mapState } from 'vuex'
import store from '../../store'
export default {
  name: 'ocr-Lawyer-Auth',
  mixins: [goBackMixin],
  data() {
    return {
      // more: false,
      tab: 1,
      tabList: [
        {
          value: 1,
          text: '律师',
          selectText: '我是律师',
        },
        {
          value: 2,
          text: '法律工作者',
          selectText: '法律工作者',
        },
        {
          value: 3,
          text: '其他',
          selectText: '其他',
        },
      ],
      page: 0,
      size: 10,
      lawFirmList: [],
      lawFirmName: '',
      lawFirmId: 0,
      isRequest: true, // 是否可以继续请求
      orgShow: false,
      lawyerName: '',
      jobNumber: '',
      searchLawyerName: '',
      searchLawFirmName: '',
      jobType: '律师',
      callingCardUrl: '',
      isUpdate: true,
      isClick: false,
      showBack: true,
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
    validate() {
      if (this.tab === 2 && !this.callingCardUrl) {
        return false
      }
      if (this.tab === 3) {
        return !!(this.lawyerName && this.jobNumber)
      } else {
        return !!(this.lawyerName && this.lawFirmName && this.jobNumber)
      }
    },
  },
  methods: {
    // 调用OCR识别文字
    async getOCR(url) {
      const res = await this.$axios.post(`${this.$base}/management/accurate`, 'imgStr=' + url, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      return res
    },
    // 识别图片内容填充
    async getInfoFromImg(val) {
      let url = val
      if (url.slice(0, 4) !== 'http') {
        if (window.location.href.slice(0, 5) === 'https') {
          url = `https:${url}`
        } else {
          url = `http:${url}`
        }
      }
      try {
        const { data } = await this.getOCR(url)
        this.setJobNum = false
        this.setLawfirmName = false
        if (data.code !== 200 || !data.data) {
          this.$toast.fail(data.message)
          return
        }

        const wordsResult = data.data.words_result

        if (this.tab !== 1) {
          const keyword = this.tab === 2 ? '工作者' : /法官|检察/
          const flag = wordsResult.some((v) => v.words.includes(keyword))
          if (!flag) {
            this.lawyerName = ''
            this.searchLawyerName = ''
            this.searchLawFirmName = ''
            this.jobNumber = ''
            this.$notify(this.tab === 2 ? '未识别到正确的法律工作者证，请重新拍摄上传' : '未识别到正确的证件照，请重新拍摄上传')
            return
          }
        }

        wordsResult.forEach((v, i) => {
          const words = v.words
          if (words.endsWith('事务所')) {
            const arr = words.split(' ')
            this.lawFirmName = arr.length > 1 ? arr[1] : arr[0]
            this.setLawfirmName = true
          }

          let num = 0
          if (words.startsWith('执业证号')) {
            const numStr = words.substring(4).replace(/\s+/g, '')
            num = Number(numStr)
          } else {
            num = Number(words.replace(/\s+/g, ''))
          }
          if (words.replace(/\s/g, '') === '持证人') {
            this.curIndex = i
          }
          if (num && !this.setJobNum && num.toString().length > 10) {
            this.jobNumber = num + ''
            this.setJobNum = true
          }
        })

        const regName = /^[\u4e00-\u9fa5]+(·[\u4e00-\u9fa5]+)*$/
        if (this.curIndex < data.data.words_result.length && this.curIndex > 0) {
          const preWord = wordsResult[this.curIndex - 1].words.replace(/\s+/g, '')
          const nextWord = wordsResult[this.curIndex + 1].words.replace(/\s+/g, '')
          this.lawyerName = regName.test(nextWord) ? nextWord : preWord.replace(/（/g, '')
        }

        if (this.setLawfirmName) {
          // this.orgShow = true
          this.inputName()
        }
      } catch (err) {
        console.log(err)
      }
    },
    // 根据执业证号查找
    async searchLawyfirmByJobNum() {
      try {
        const res = await this.$axios.post(`${this.$pydataBase}/search/lawyer`, {
          account_code: this.jobNumber,
        })
        const { code, data } = res.data
        if (code === 200) {
          this.searchLawyerName = data.name
          this.searchLawFirmName = data.dept_name
        } else {
          this.searchLawyerName = ''
          this.searchLawFirmName = ''
        }
      } catch (err) {
        console.log(err)
      }
    },
    // 执业证号输入框失焦
    async blurJobNum() {
      await this.searchLawyfirmByJobNum()
      if (this.searchLawFirmName && !this.lawFirmName) {
        this.orgShow = true
        this.lawFirmName = this.searchLawyerName
        await this.inputName()
      }
      if (this.lawFirmName !== this.searchLawFirmName) {
        this.$notify('请检查律所名称和执业证号是否正确')
      }
    },
    // 切换律师、工作者、其他
    onTab(item) {
      this.lawFirmList = []
      this.lawFirmId = 0
      this.page = 0
      this.lawFirmName = ''
      this.isRequest = true
      this.tab = item.value
      if (this.callingCardUrl) this.getInfoFromImg(this.callingCardUrl)
    },
    // 律所列表点击事件
    async nameClick(item) {
      this.city = item.city
      this.lawFirmName = item.name
      // this.lawFirmList = []
      this.page = 0
      this.lawFirmId = item.id
      this.orgShow = false
      if (!this.searchLawFirmName) await this.searchLawyfirmByJobNum()
      if (this.lawFirmName !== this.searchLawFirmName) {
        this.$notify('请检查律所名称和执业证号是否正确')
      }
    },
    focus() {
      this.orgShow = true
      this.getOfficeList()
    },
    blur() {
      this.page = 0
      setTimeout(async () => {
        this.orgShow = false
      }, 200)
    },
    async inputName() {
      this.lawFirmId = 0
      this.page = 0
      await this.getOfficeList()
    },
    load(e) {
      if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight && this.isRequest) {
        this.page++
        this.getOfficeList()
      }
    },
    // 请求事务所接口
    async getOfficeList() {
      const obj = {
        city: '',
        pageNum: this.page,
        name: this.lawFirmName,
      }
      if (this.tab === 3) {
        obj.type = '其他'
      }
      const res = await this.$axios.get(`${this.$base}/lts/Lawfirm/lawfirmlist`, {
        params: obj,
      })
      const { code, data } = res.data
      if (code === '200' && data !== null) {
        // 如果页码是0，那就替换原来的数组
        if (this.page === 0) {
          this.lawFirmList = data.lawFirms
        } else {
          // 如果页码不是0，那就在原来的数组的基础上加上数据
          this.lawFirmList = this.lawFirmList.concat(data.lawFirms)
        }
        // 如果返回的数据的长度小于每页的大小，那就说明没数据了，不允许继续下拉
        if (data.lawFirms.length < this.size) {
          this.isRequest = false
        } else {
          this.isRequest = true
        }
      }
    },
    // 返回上一个页面
    back() {
      if (this.$router.length <= 1) {
        console.log('直接返回到原生端')
        this.backToNative()
      } else {
        history.back(-1)
      }
    },
    // 点击添加图片
    addImg() {
      const ua = navigator.userAgent
      console.log('addImg...s')
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.toAndrPickFile(3)
        console.log('ua...1')
        return false
      } else if (ua === 'fb-flutter-Webview') {
        console.log('ua...flutter')
        window.toAndrPickFile.postMessage('3')
        return false
      } else {
        console.log('ua...2')
        if (document.getElementById('file')) {
          document.getElementById('file').remove()
        }
        const ipt = document.createElement('input')
        ipt.id = 'file' // 设置ID以便删除
        ipt.type = 'file'
        // ipt.multiple = true // 开启支持多选
        ipt.style.display = 'none'
        document.body.appendChild(ipt)
        document.getElementById('file').onchange = () => {
          const f = ipt.files[0]
          const qiniu = new this.$qiniu()
          qiniu.UploadFile(
            'DEFAULT',
            f,
            {
              next: (file) => {},
              complete: (res, file) => {},
              error: () => {
                this.$toast('上传失败，请重新选择')
              },
            },
            (fileopt, key, file) => {
              this.callingCardUrl = qiniuBase + '/' + key
              document.getElementById('file').remove()
              this.getInfoFromImg(this.callingCardUrl)
            },
            true
          )
        }
        ipt.click()
      }
    },
    // 接收原生端上传回调
    uploadFilesResultCallBack(data) {
      const list = JSON.parse(data)
      this.callingCardUrl = list[0].url
      if (this.callingCardUrl) this.getInfoFromImg(this.callingCardUrl)
    },
    // 获取个人信息
    init() {
      this.$axios.get(`${this.$base}/farbun/lawyer/lawyer_Info`).then((res) => {
        const { code, data } = res.data
        if (code === 200 && data) {
          this.lawyerName = data.lawyerName
          this.lawFirmName = data.lawFirmName
          this.lawFirmId = data.lawFirmId
          this.jobNumber = data.jobNumber
          this.callingCardUrl = data.callingCardUrl
          this.id = data.id
          this.isUpdate = true
          if (data.jobType === '律师' || data.jobType === '实习律师' || data.jobType === '公职律师' || data.jobType === '专职律师') {
            this.tab = 1
          } else if (data.jobType === '法律工作者') {
            this.tab = 2
          } else if (data.jobType === '其他') {
            this.tab = 3
          }
        } else {
          this.isUpdate = false
          if (this.userInfo) {
            this.lawyerName = this.userInfo.nickName
          }
        }
      })
    },
    // 认证
    async sure() {
      await this.searchLawyfirmByJobNum()
      if (this.tab === 2 && !this.callingCardUrl) {
        this.$notify('请拍摄法律工作者证')
        return
      }
      // const jobEmail = this.jobEmail.replace('＠', '@')
      if (this.isUpdate) {
        const param = {
          id: this.id,
          lawFirmId: this.lawFirmId,
          lawyerName: this.lawyerName,
          lawFirmName: this.lawFirmName,
          userId: this.userInfo.id,
          county: this.city,
          jobNumber: this.jobNumber,
          callingCardUrl: this.callingCardUrl,
        }
        if (this.tab === 1) {
          param.jobType = '律师'
        } else if (this.tab === 2) {
          param.jobType = '法律工作者'
        } else if (this.tab === 3) {
          param.jobType = '其他'
        }
        this.isClick = true
        this.$axios
          .post(`${this.$base}/farbun/lawyer/updateByPrimaryKey`, param)
          .then((res) => {
            if (res.data.code === 200) {
              console.log('2设置成功')
              store.commit('user/setLawyerFlag', true)
              console.log(store, this)
              this.$notify({
                message: res.data.message,
                type: 'success',
              })
              this.init()
              this.isClick = false
            } else {
              this.$notify(res.data.message)
            }
          })
          .catch((err) => {
            console.log(err)
            // this.isClick = false
          })
      } else {
        const lawFirmName = this.lawFirmName
        if (this.tab === 1 || this.tab === 2) {
          // 如果是输入的，必须满足律所的名字大于7，且律所的名字的最后一个是所
          if (this.lawFirmId === 0 && (lawFirmName.length < 7 || lawFirmName.substring(lawFirmName.length - 1) !== '所')) {
            return this.$notify('请输入正确律所的名称')
          }
        } else if (this.tab === 3) {
          if (this.lawFirmId === 0 && lawFirmName.length < 7) {
            return this.$notify('请输入正确工作单位的名称')
          }
        }
        const param = {
          lawFirmId: this.lawFirmId,
          lawyerName: this.lawyerName,
          lawFirmName: this.lawFirmName,
          userId: this.userInfo.id,
          jobNumber: this.jobNumber,
          callingCardUrl: this.callingCardUrl,
        }
        if (this.tab === 1) {
          param.jobType = '律师'
        } else if (this.tab === 2) {
          param.jobType = '法律工作者'
        } else if (this.tab === 3) {
          param.jobType = '其他'
        }
        this.isClick = true
        this.$axios
          .post(`${this.$base}/farbun/lawyer/saveLawyer`, param)
          .then((res) => {
            if (res.data.code === 200) {
              console.log('设置成功')
              store.commit('user/setLawyerFlag', true)
              this.$notify({
                message: res.data.message,
                type: 'success',
              })
              this.init()
              this.isClick = false
            } else {
              this.$notify(res.data.message)
            }
          })
          .catch((err) => {
            // this.isClick = false
            console.log(err)
          })
      }
    },
  },
  mounted() {
    this.showBack = this.$route?.query.showLay === '0' ? 0 : 1
    this.init()
  },
  created() {
    // 关联组件内方法ocrCaseResultCallback 至 JSBridge/uploadFiles
    this.$hook(this.$bridge.uploadFiles, this.uploadFilesResultCallBack)
    // 组件销毁的时候释放桥接方法
    this.$once('hook:destroyed', () => {
      this.$unhook(this.$bridge.uploadFiles)
    })
  },
}
</script>
<style lang="stylus" scoped>
.tab-box
  width 100%
  display flex
  flex-direction row
  align-items center
  justify-content center
  margin-bottom 20px
  & .tab
    width 33%
    padding 5px 10px
    box-sizing border-box
    border 1px solid #cccccc
    cursor pointer
    display flex
    align-items center
    justify-content center
.calling-card
  padding 10px 15px
  box-sizing border-box
  font-size 14px
  display flex
  flex-direction row
  // align-items center
  & > div
    width 200px
    min-height 50px
    border 1px solid #CCC
    margin-left 20px
    display flex
    flex-direction row
    align-items center
    justify-content center
    & > img
      width 100%
.numlist
  width 100%
  max-height 180px
  overflow auto
  background rgba(255, 255, 255, 1)
  box-shadow 0px 3px 8px 1px rgba(190, 190, 190, 0.64)
  border-radius 5px
  position fixed
  z-index 999
  li
    height 35px
    line-height 35px
    text-indent 10px
    white-space normal
    overflow hidden
    text-overflow ellipsis
    font-size 14px
    color #333
/deep/ .van-field .van-cell__title
  max-width 2rem
/deep/ .van-cell__value
  color black
.van-popup--buttom
  width 100%
  height 50%
  position fixed
  // top 15.4rem
// /deep/ .location
// display flex
/deep/ .location .van-cell__value
  display flex
  // position relative
  // left -4.5rem
  div
    margin-left 0.5rem
.hello
  color black
  position static
#Lawyerauth-page
  width 100%
  height 100%
  position relative
.head
  display flex
  justify-content space-between
  align-items center
  border-bottom-color green
  .left
    font-size 0.8rem
    font-weight 900
    display flex
    align-items center
    .arrow
      font-size 15px
      padding-left 0.2rem
  .right
    margin-right 0.2rem
    .intro
      font-size 12
      color #CCCCCC
      margin-right 0.5rem
// .province
// position fixed
// bottom 0
.area
  .van-cell__value
    color red
.cell
  display flex
  align-items center
.sex
  width 1.8rem
  position absolute
  // right -8.2rem
  background white
  z-index 1000
  right 0.4rem
  .man
    border 1px solid #cccccc
    height 0.7rem
    text-align center
    line-height 0.7rem
.educations
  width 1.8rem
  position absolute
  background white
  z-index 1000
  right 0.4rem
  .education
    border 1px solid #cccccc
    height 1.0rem
    text-align center
    line-height 1.0rem
.photo
  margin-top 0.4rem
  margin-left 0.4rem
  font-size 0.38rem
  .touxiang
    // color #666666
    margin-bottom 0.5rem
  .icon-add1
    color #ccc
    font-size 1rem
  .imgs
    position relative
    width 1rem
    height 1rem
    .img
      width 1rem
      height 1rem
      position absolute
      top -15px
      img
        width 100%
        height 100%
        border-radius 50%
.goback
  width 100%
  background #4B9EFB
  color white
  position fixed
  bottom 0
  cursor pointer
  &.gray
    background #666
    border #666
    color #fff
    cursor:not-allowed;
    pointer-events:none;
    &:hover
      background #666
      border #666
</style>
